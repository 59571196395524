import React from 'react';
import ProductCard from '../ProductCard/ProductCard';
import './ProductList.css'; // Optional: if you need specific styles for the product list

const ProductList = ({ products, likedProducts, toggleLike, loading, hasMore }) => {
  return (
    <div className="row">
      {products.length > 0 ? (
        products.map(product => (
          <div key={product.recordID} className="col-6 col-sm-6 col-md-4 mb-4">
            <ProductCard
              product={product}
              isLiked={likedProducts.has(product.productID)}
              toggleLike={toggleLike}
            />
          </div>
        ))
      ) : (
        !loading && <p className="text-center w-100">We hebben helaas geen producten gevonden.</p>
      )}
      {/* Removed the loading text */}
    </div>
  );
};

export default ProductList;