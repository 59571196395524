import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ZoolmaatjesView.css'; // Import new custom CSS
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone'; // Import the icon
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import ZoolmaatjesDetailView from './ZoolmaatjesDetailView';

const ZoolmaatjesView = ({ senderEmail, senderId, senderFirstname }) => {
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recipientName, setRecipientName] = useState('');
    const [emailStatus, setEmailStatus] = useState({});
    const [currentView, setCurrentView] = useState('main'); // 'main' or 'detail'
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        const fetchMatches = async () => {
            try {
                console.log('Fetching matches for user:', senderId);
                const response = await axios.post('/.netlify/functions/getMatches', { recordID: senderId });
                console.log('Response from getMatches:', response.data);

                if (!response.data || !Array.isArray(response.data)) {
                    throw new Error('Invalid response data');
                }

                const matchesData = response.data;

                // Check email status for each match and their liked products
                const emailStatusPromises = matchesData.map(async (match) => {
                    const emailStatusResponse = await axios.post('/.netlify/functions/emailSent', {
                        matchRecordId: match.id,
                        productRecordId: match.productID,
                        recordEmailSent: false
                    });
                    return { matchId: match.id, productID: match.productID, emailed: emailStatusResponse.data.emailed };
                });

                const emailStatuses = await Promise.all(emailStatusPromises);

                const emailStatusMap = emailStatuses.reduce((acc, status) => {
                    acc[`${status.matchId}_${status.productID}`] = status.emailed;
                    return acc;
                }, {});

                setMatches(matchesData);
                setEmailStatus(emailStatusMap);
            } catch (error) {
                setError('Error fetching matches');
                console.error('Error in fetchMatches:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMatches();
    }, [senderId]);

    const handleSendEmailFromMainView = async (recipientEmail, match) => {
        try {
            if (!senderId) throw new Error('Sender ID is not available');
            if (!recipientEmail) throw new Error('Recipient email is not available');
            if (!match.id || !match.productID) throw new Error('Match ID or Product ID is not available');
    
            console.log('Sending email with the following data:', {
                recipientEmail,
                templateId: 6171503,
                senderEmail,
                senderFirstname,
                match
            });
    
            const sendMailResponse = await axios.post('/.netlify/functions/sendMail', {
                recipientEmail,
                templateId: 6171503,
                senderEmail,
                senderFirstname,
                match
            });
    
            console.log('Email sent successfully:', sendMailResponse.data);
            setRecipientName(match.userFirstName);
            setIsModalVisible(true);
    
            setEmailStatus(prevStatus => ({
                ...prevStatus,
                [`${match.id}_${match.productID}`]: true,
            }));
    
            console.log('Saving email status to Airtable...');
            await axios.post('/.netlify/functions/emailSent', {
                matchRecordId: match.id,
                productRecordId: match.productID,
                recordEmailSent: true
            });
    
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    const handleViewDetail = (product, users) => {
        // Ensure each user object includes a correct matchId
        const updatedUsers = users.map(user => ({
            ...user,
            matchId: user.matchId // Preserve the matchId for each user
        }));
    
        setSelectedProduct({ ...product, users: updatedUsers });
        setCurrentView('detail');
    };

    const handleBackToMainView = () => {
        setCurrentView('main');
        setSelectedProduct(null);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div className='title-match py-4'>
                            <h1>Nog geen zoolmaatjes..</h1>
                            <p>Sla favorieten schoenen op om te zien wie jouw zoolmaatje is.</p>
                        </div>
        );
    }
    // Group matches by productID
    const groupedMatches = matches.reduce((acc, match) => {
        const productID = match.productID;
        if (!acc[productID]) {
            acc[productID] = {
                product: match, 
                users: []
            };
        }
        acc[productID].users.push({
            userFirstName: match.userFirstName,
            userLastName: match.userLastName,
            userEmail: match.userEmail,
            userProfilePic: match.userProfilePic,
            userOwnSize: match.userOwnSize,
            matchId: match.id,
        });
        return acc;
    }, {});

    return (
        <div>
            {currentView === 'main' ? (
                <div className="match-overview container">
                    <div className="row">
                        <div className='title-match py-4'>
                            <h1>Jouw zoolmaatjes</h1>
                            <p>Stuur een automatisch bericht naar jouw zoolmaatje om samen schoenen te kopen!</p>
                        </div>
                        {Object.keys(groupedMatches).length === 0 ? (
                            <p className="text-center w-100">We hebben helaas geen matches gevonden.</p>
                        ) : (
                            Object.values(groupedMatches).map(({ product, users }) => (
                                <div key={product.productID} className="col-6 col-sm-6 col-md-4 mb-4">
                                    <div className="card product-card h-100 text-decoration-none">
                                        <div className="user-profile-pics-wrapper">
                                            <AvatarGroup max={4}>
                                                {users.map(user => (
                                                    <Avatar
                                                        key={user.userEmail}
                                                        alt={`${user.userFirstName} ${user.userLastName}`}
                                                        src={user.userProfilePic || '/defaultProfile.png'}
                                                        sx={{ width: 40, height: 40 }} 
                                                    />
                                                ))}
                                            </AvatarGroup>
                                            <div className='card-header-wrapper'>
                                                <div className="mb-0 zoolmaatjes-text">
                                                {users.length > 1 ? (
                                                    <p className="mb-0">
                                                        {users.length} zoolmaatjes
                                                    </p>
                                                ) : (
                                                    <p className="mb-0">
                                                        {users[0].userFirstName}
                                                    </p>
                                                )}
                                                    {users[0].userOwnSize && users[0].userOwnSize.split(',').length === 2 && (
                                                        <p className="sizeText mb-0">
                                                            Links: {users[0].userOwnSize.split(',')[0]}, Rechts: {users[0].userOwnSize.split(',')[1]}
                                                        </p>
                                                    )}
                                                </div>
                                                {users.length > 1 ? (
                                                    <button className="detail-view-btn" onClick={() => handleViewDetail(product, users)}>
                                                        <ArrowCircleRightIcon />
                                                    </button>
                                                ) : (
                                                    emailStatus[`${users[0].matchId}_${product.productID}`] ? (
                                                        <button className='mailed-icon' disabled>
                                                            <EmailTwoToneIcon />
                                                        </button>
                                                    ) : (
                                                        <button className='mail-icon' onClick={() => handleSendEmailFromMainView(users[0].userEmail, product)}>
                                                            <EmailTwoToneIcon />
                                                        </button>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        {product.productPicture && (
                                            <a href={product.productLink} target="_blank" rel="noopener noreferrer">
                                                <img src={product.productPicture} className="card-img-top" alt={product.title} />
                                            </a>
                                        )}
                                        <div className="card-body">
                                            {product.productLink && (
                                                <a href={product.productLink} target="_blank" rel="noopener noreferrer">
                                                    <h5 className="card-title">{product.title}</h5>
                                                </a>
                                            )}
                                            {product.price && (
                                                <div className="card-price-supplier">
                                                    <p className="card-price">
                                                        <strong>€ {product.price}</strong>
                                                    </p>
                                                    {product.supplierLogo && (
                                                        <div className="supplier-logo">
                                                            <img src={product.supplierLogo} alt="Supplier Logo" className="img-fluid" />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            ) : (
                <ZoolmaatjesDetailView
                    product={selectedProduct}
                    emailStatus={emailStatus}
                    handleBack={handleBackToMainView} // Pass the back function
                    senderId={senderId}  // Pass senderId
        senderEmail={senderEmail}  // Pass senderEmail
        senderFirstname={senderFirstname}  // Pass senderFirstname
        setRecipientName={setRecipientName}  // Pass setRecipientName
        setIsModalVisible={setIsModalVisible}  // Pass setIsModalVisible
        setEmailStatus={setEmailStatus}  // Pass setEmailStatus
                />
            )}
    
            {/* Modal for showing email sent message */}
            {isModalVisible && (
                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content px-2">
                            <div className="modal-header">
                                <h5 className="modal-title py-2 fw-bold">Email verstuurd!</h5>
                                <button type="button" className="close" onClick={closeModal} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body py-4">
                                Super! Je bericht is verzonden. Nu is het wachten op een reactie van {recipientName}. Veel succes!
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ZoolmaatjesView;