// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css'; // Import your custom CSS
import logo from '../assets/logo.png'; // Import the logo image

const Header = () => {
  const { currentUser } = useAuth();

  return (
    <nav className="navbar navbar-light bg-white header-bg shadow-sm py-2 px-2 sticky-header">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Zoolmaatje Logo" height="30" className="d-inline-block align-top me-2" />
          <span className='fw-bold text-primary custom-primary custom-font'>Zoolmaatje</span>
        </Link>
        <ul className="navbar-nav ms-auto">
          {!currentUser && (
            <li className="nav-item">
              <Link className="nav-link" to="/registreer">Aanmelden</Link>
            </li>
          )}
          {currentUser && (
            <li className="nav-item">
              <Link className="nav-link" to="/dashboard">
                <FontAwesomeIcon icon={faUser} size="lg" />
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Header;