import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../hooks/useAuth';
import ReactSlider from 'react-slider';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Dashboard.css'; // Import custom CSS
import Sidebar from './Sidebar';
import EditableField from './EditableField';
import ZoolmaatjesView from './ZoolmaatjesView'; // Import ZoolmaatjesView

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const [currentView, setCurrentView] = useState('profile'); // Track current view
  const [isOpen, setIsOpen] = useState(false); // State for sidebar
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('/.netlify/functions/getAirtable', { uid: currentUser.uid });
        setUserData(response.data);
        console.log('Fetched user data:', response.data);
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);

  const handleInputChange = (name, value) => {
    setUpdatedData(prevState => {
      const newState = { ...prevState, [name]: value };
      console.log('Updated Data:', newState); // Log the updated state
      return newState;
    });
  };

  const handleUpdate = async (profilePicUrl) => {
    try {
      const dataToSend = { ...updatedData, profilePic: profilePicUrl || updatedData.profilePic };
      console.log('Final Updated Data in handleUpdate:', dataToSend); // Log the final data
    
      await axios.post('/.netlify/functions/saveAirtable', {
        recordID: userData.id,
        uid: userData.fields.uid,
        email: dataToSend.email || userData.fields.email,
        firstName: dataToSend.firstName || userData.fields.firstName,
        lastName: dataToSend.lastName || userData.fields.lastName,
        shoeSizeLeft: dataToSend.shoeSizeLeft || userData.fields.shoeSizeLeft,
        shoeSizeRight: dataToSend.shoeSizeRight || userData.fields.shoeSizeRight,
        profilePic: dataToSend.profilePic || userData.fields.profilePic,
      });

      setUserData({ ...userData, fields: { ...userData.fields, ...dataToSend } });
    } catch (error) {
      console.error('Error updating user data:', error.message);
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  if (!userData) {
    return (
      <div className="dashboard-container">
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} setView={setCurrentView} />
        <div className="content d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className="dashboard-container">
      <Sidebar
        isOpen={isOpen}
        toggleSidebar={toggleSidebar}
        setView={setCurrentView}
        userData={userData}
        handleUpdate={handleUpdate} // Passing handleUpdate to Sidebar
      />
      <div className="content">
        {currentView === 'profile' && (
          <ProfileView
            userData={userData}
            updatedData={updatedData}
            handleInputChange={handleInputChange}
            handleUpdate={handleUpdate} // Pass handleUpdate to ProfileView
          />
        )}
        {currentView === 'zoolmaatjes' && userData.fields.matchSize && (
          <ZoolmaatjesView
            matchSize={userData.fields.matchSize}
            senderEmail={userData.fields.email}
            senderId={userData.id}
            senderFirstname={userData.fields.firstName}
          />
        )}
        {currentView === 'berichten' && <BerichtenView />}
      </div>
    </div>
  );
};

const ProfileView = ({ userData, updatedData, handleInputChange, handleUpdate }) => {
  return (
    <div className="profile-form card">
      <div className="card-body">
        <form>
          <EditableField
            label="Voornaam"
            name="firstName"
            value={updatedData.firstName || userData.fields.firstName}
            onChange={handleInputChange}
          />
          <EditableField
            label="Achternaam"
            name="lastName"
            value={updatedData.lastName || userData.fields.lastName}
            onChange={handleInputChange}
          />
          <EditableField
            label="Email"
            name="email"
            value={updatedData.email || userData.fields.email}
            onChange={handleInputChange}
          />
          <div className="form-group">
            <label htmlFor="shoeSizeLeft">Schoenmaat links</label>
            <ReactSlider
              className="slider"
              thumbClassName="thumb"
              trackClassName="track"
              min={30}
              max={50}
              step={1}
              value={Number(updatedData.shoeSizeLeft) || Number(userData.fields.shoeSizeLeft)}
              onChange={(value) => handleInputChange('shoeSizeLeft', value.toString())}
              renderThumb={(props, state) => <div {...props}></div>}
            />
            <div className="size-label">{updatedData.shoeSizeLeft || userData.fields.shoeSizeLeft}</div>
          </div>
          <div className="form-group">
            <label htmlFor="shoeSizeRight">Schoenmaat rechts</label>
            <ReactSlider
              className="slider"
              thumbClassName="thumb"
              trackClassName="track"
              min={30}
              max={50}
              step={1}
              value={Number(updatedData.shoeSizeRight) || Number(userData.fields.shoeSizeRight)}
              onChange={(value) => handleInputChange('shoeSizeRight', value.toString())}
              renderThumb={(props, state) => <div {...props}></div>}
            />
            <div className="size-label">{updatedData.shoeSizeRight || userData.fields.shoeSizeRight}</div>
          </div>
          <button type="button" className="btn btn-primary mt-3" onClick={() => handleUpdate()}>Bijwerken</button>
        </form>
      </div>
    </div>
  );
};

const BerichtenView = () => (
  <div>Berichten view</div>
);

export default Dashboard;