// src/components/Privacybeleid.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Spinner, Alert, Button } from 'react-bootstrap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './ContentPage.css'

const Privacybeleid = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/.netlify/functions/fetchContent', {
          params: { id: '6KyHa2GBwvYC0nSDhQUhdV' } // Replace with your actual entry ID
        });
        setData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const handleChangeCookiePreferences = () => {
    if (window.Cookiebot) {
      window.Cookiebot.show();
    } else {
      console.error('Cookiebot is not loaded');
    }
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <Container className='blog-container'>
      <h1 className="blog-title">{data.staticTitle}</h1>
      <div>{documentToReactComponents(data.staticContent)}</div>
      <Button onClick={handleChangeCookiePreferences} variant="secondary" className="mt-3 ms-3">
        Voorkeuren aanpassen
      </Button>
    </Container>
  );
};

export default Privacybeleid;