// src/components/Disclaimer.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Spinner, Alert } from 'react-bootstrap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './ContentPage.css'

const Disclaimer = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/.netlify/functions/fetchContent', {
          params: { id: '8bPGSxKeI2H7txQbIAZtq' } // Replace with your actual entry ID
        });
        setData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <Container className='blog-container'>
      <h1 className="blog-title">{data.staticTitle}</h1>
      <div>{documentToReactComponents(data.staticContent)}</div>
    </Container>
  );
};

export default Disclaimer;