// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import ProductOverview from './components/ProductOverview/ProductOverview';
import Privacybeleid from './components/Privacybeleid';
import Disclaimer from './components/Disclaimer';
import ContentPage from './components/ContentPage';
import Header from './components/Header';
import Footer from './components/Footer';
import { AuthProvider } from './hooks/useAuth';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'; // Import your custom CSS

const App = () => {
  return (
    <AuthProvider>
      <div className="d-flex flex-column min-vh-100">
        <Header />
        <main className="flex-grow-1">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/registreer" element={<Register />} />
            <Route path="/dashboard" element={<ProtectedRoute element={Dashboard} />} />
            <Route path="/schoenen" element={<ProductOverview />} />
            <Route path="/privacybeleid" element={<Privacybeleid />} />
            <Route path="/Disclaimer" element={<Disclaimer />} />

            {/* Dynamic content pages route */}
            <Route path="/inspiratie/:slug" element={<ContentPage />} />

            {/* Add other routes here */}
          </Routes>
        </main>
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default App;