import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Skeleton-specific CSS

const SkeletonLoader = () => {
  return (
    <div className="row">
      {Array(6).fill().map((_, index) => (
        <div key={index} className="col-6 col-sm-6 col-md-4 mb-4">
          <div className="card product-card h-100 text-decoration-none">
            <Skeleton height={200} />
            <div className="card-body">
              <h5 className="card-title"><Skeleton width="80%" /></h5>
              <div className="card-price-supplier">
                <p className="card-price"><Skeleton width="50%" /></p>
                <div className="supplier-logo">
                  <Skeleton width={30} height={30} circle={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonLoader;