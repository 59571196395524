import React from 'react';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';

const ZoolmaatjesDetailView = ({ product, handleBack, emailStatus, senderId, // Add this prop
senderEmail, // Add this prop
senderFirstname, // Add this prop
setRecipientName, // Add this prop
setIsModalVisible, // Add this prop
setEmailStatus // Add this prop
  }) => {
    // Function to handle sending an email from the detail view
    const handleSendEmailFromDetailView = async (recipientEmail, match) => {
        try {
            if (!senderId) throw new Error('Sender ID is not available');
            if (!recipientEmail) throw new Error('Recipient email is not available');
            if (!match.matchId || !match.productID) throw new Error('Match ID or Product ID is not available');

            console.log('Sending email with the following data:', {
                recipientEmail,
                templateId: 6171503,
                senderEmail,
                senderFirstname,
                match
            });

            const sendMailResponse = await axios.post('/.netlify/functions/sendMail', {
                recipientEmail,
                templateId: 6171503,
                senderEmail,
                senderFirstname,
                match
            });

            console.log('Email sent successfully:', sendMailResponse.data);
            setRecipientName(match.userFirstName);
            setIsModalVisible(true);

            setEmailStatus(prevStatus => ({
                ...prevStatus,
                [`${match.matchId}_${match.productID}`]: true,
            }));

            console.log('Saving email status to Airtable...');
            await axios.post('/.netlify/functions/emailSent', {
                matchRecordId: match.matchId,
                productRecordId: match.productID,
                recordEmailSent: true
            });

        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    return (
        <div className="detail-view-container">
            <div className="row">
                <button onClick={handleBack} className="btn-back">
                    <ArrowBackIosNewRoundedIcon /> Terug
                </button>
                <h1 className="title-match py-4">{product.title}</h1>
                {product.users.map((user) => {
                    return (
                        <div key={user.userEmail} className="col-6 col-sm-6 col-md-4 mb-4">
                            <div className="card product-card h-100 text-decoration-none">
                                <div className="user-profile-pics-wrapper">
                                    <Avatar
                                        alt={`${user.userFirstName} ${user.userLastName}`}
                                        src={user.userProfilePic || '/defaultProfile.png'}
                                        sx={{ width: 40, height: 40 }} 
                                    />
                                    <div className='card-header-wrapper'>
                                        <div className="mb-0 zoolmaatjes-text">
                                            <p className="mb-0">{user.userFirstName} {user.userLastName}</p>
                                            {user.userOwnSize && user.userOwnSize.split(',').length === 2 && (
                                                <p className="sizeText mb-0">
                                                    Links: {user.userOwnSize.split(',')[0]}, Rechts: {user.userOwnSize.split(',')[1]}
                                                </p>
                                            )}
                                        </div>
                                        {emailStatus[`${user.matchId}_${product.productID}`] ? (
                                            <button className='mailed-icon' disabled>
                                                <EmailTwoToneIcon />
                                            </button>
                                        ) : (
                                            <button
                                                className='mail-icon'
                                                onClick={() => {
                                                    const matchWithCorrectId = {
                                                        ...product,
                                                        matchId: user.matchId,  // Ensure matchId corresponds to the user
                                                        userFirstName: user.userFirstName,
                                                        userLastName: user.userLastName,
                                                        userEmail: user.userEmail,
                                                        userProfilePic: user.userProfilePic,
                                                        userOwnSize: user.userOwnSize,
                                                    };

                                                    handleSendEmailFromDetailView(user.userEmail, matchWithCorrectId);
                                                }}
                                            >
                                                <EmailTwoToneIcon />
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <a href={product.productLink} target="_blank" rel="noopener noreferrer">
                                    <div className="product-image-wrapper">
                                        <img src={product.productPicture} alt={product.title} className="card-img-top img-fluid" />
                                    </div>
                                    <div className="card-body">
                                        {product.productLink && (
                                            <a href={product.productLink} target="_blank" rel="noopener noreferrer">
                                                <h5 className="product-title">{product.title}</h5>
                                            </a>
                                        )}
                                        {product.price && (
                                            <div className="card-price-supplier">
                                                <p className="card-price">
                                                    <strong>€ {product.price}</strong>
                                                </p>
                                                {product.supplierLogo && (
                                                    <div className="supplier-logo">
                                                        <img src={product.supplierLogo} alt="Supplier Logo" className="img-fluid" />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </a>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ZoolmaatjesDetailView;