import React, { useState } from 'react';
import ReactSlider from 'react-slider';
import './Filters.css'; // Import the general Filters CSS file

const PriceFilter = ({ filterPrice, setFilterPrice }) => {
  const [timeoutId, setTimeoutId] = useState(null);

  const handlePriceChange = (value) => {
    setFilterPrice(value);

    // Clear any existing timeout to prevent multiple triggers
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout to delay the application of the filter
    const newTimeoutId = setTimeout(() => {
      // Trigger the filter application after the user has stopped sliding
      setFilterPrice(value);
    }, 300); // Adjust the delay time as needed

    setTimeoutId(newTimeoutId);
  };

  return (
    <div className="filter-item price-filter">
      <h5>Prijs</h5>
      <div className="price-label">€{filterPrice}</div>
      <ReactSlider
        className="slider"
        thumbClassName="thumb"
        trackClassName="track"
        value={filterPrice}
        onChange={handlePriceChange}
        min={0}
        max={250}
      />
    </div>
  );
};

export default PriceFilter;