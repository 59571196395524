import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css'; // Import your custom CSS

const Home = () => {
    const [homepageContent, setHomepageContent] = useState(null);
    const [productLinks, setProductLinks] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchContent = async () => {
            try {
                console.log('Fetching homepage content...');
                const homepageResponse = await axios.get('/.netlify/functions/fetchContent?type=homepage');
                console.log('Homepage content fetched:', homepageResponse.data);

                console.log('Fetching product links...');
                const productLinksResponse = await axios.get('/.netlify/functions/fetchContent?type=productLinks');
                console.log('Product links fetched:', productLinksResponse.data);

          // Simulate a delay for testing skeleton loading
          setTimeout(() => {
            setHomepageContent(homepageResponse.data[0]);
            setProductLinks(productLinksResponse.data);
        }, 0); // 3-second delay

            } catch (error) {
                console.error('Error fetching content:', error);
                setError('Failed to load content. Please try again later.');
            }
        };

        fetchContent();
    }, []); // Empty dependency array ensures this useEffect runs only once

    if (error) {
        return <div>{error}</div>;
    }

    if (!homepageContent) {
      return (
          <Container className="mt-5">
              <div className="skeleton skeleton-title"></div>
              <div className="product-links-wrapper mt-5">
                  <div className="product-links d-flex flex-wrap gap-4 justify-content-center">
                      {Array(6).fill().map((_, index) => (
                          <div key={index} className="skeleton skeleton-card"></div>
                      ))}
                  </div>
              </div>
          </Container>
      );
  }

    const { heroTitle, body, heroImage } = homepageContent;

    const backgroundImageStyle = {
        backgroundImage: `url(${heroImage.fields.file.url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '70vh'
    };

    return (
      <>
          <div className="home-bg d-flex justify-content-center align-items-center" style={backgroundImageStyle}>
              <div className="text-center">
                  <h1 className="text-white display-3 fw-bold mb-5">{heroTitle}</h1>
                  <Link to="/registreer">
                      <button className="btn custom-button fw-bold px-4 py-3 mt-4">Aanmelden</button>
                  </Link>
              </div>
          </div>
          <Container className="product-links-wrapper mt-5">
              <div className="product-links">
                  {productLinks.length > 0 ? productLinks.map((link, index) => {
                      const backgroundImage = link.categoryBackground && link.categoryBackground.fields && link.categoryBackground.fields.file ? link.categoryBackground.fields.file.url : '';
                      return (
                          <div className="card" key={index}>
                              <Link to={link.categoryLink || '#'} className="text-decoration-none">
                                  <div className="card-body" style={{ backgroundImage: `url(${backgroundImage})` }}>
                                      <h5 className="card-title">{link.categoryName || 'No Title'}</h5>
                                  </div>
                              </Link>
                          </div>
                      );
                  }) : <p>No product links available</p>}
              </div>
          </Container>
            <div className="content-section-wrapper">
                <Container className="content-section mt-5 py-5 mb-5">
                    {documentToReactComponents(body)}
                </Container>
            </div>
        </>
    );
};

export default Home;