// src/components/EditableField.js
import React, { useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const EditableField = ({ label, name, value, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    onChange(name, e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      {isEditing ? (
        <input
          type="text"
          className="form-control"
          id={name}
          name={name}
          value={value}
          onChange={handleInputChange}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        <div className="editable-field" onClick={handleEditClick}>
          <span>{value}</span>
          <FaPencilAlt className="ml-2 text-secondary" />
        </div>
      )}
    </div>
  );
};

export default EditableField;