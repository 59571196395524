import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, fetchSignInMethodsForEmail, signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../firebase';
import { useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Register.css'; // Import your custom CSS
import axios from 'axios'; // Import axios

const Register = () => {
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [shoeSizeLeft, setShoeSizeLeft] = useState('20');
  const [shoeSizeRight, setShoeSizeRight] = useState('38');
  const [error, setError] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [isNewUser, setIsNewUser] = useState(false); // Flag to indicate if the user is new
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    console.log("Current User:", currentUser);
    console.log("Is New User:", isNewUser);
    console.log("Step:", step);
  }, [currentUser, isNewUser, step]);

  if (currentUser && !isNewUser) {
    return <Navigate to="/dashboard" />;
  }

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("Google Sign-In Result:", result);

      const isNew = result._tokenResponse?.isNewUser;
      console.log("Is New User from Google Sign-In:", isNew);

    const profilePicUrl = result.user.photoURL; // Get profile picture URL
    console.log("Profile Picture URL:", profilePicUrl);
    setProfilePic(profilePicUrl); // Set the profile picture URL state

      setIsNewUser(isNew);
      if (isNew) {
        setStep(2);
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Google sign-in error:', error.message);
      setError('Google sign-in failed. Please try again.');
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setError('');
    console.log('Email form submitted with:', { email, password });
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      console.log('Sign-in methods:', signInMethods);
      if (signInMethods.length > 0) {
        // Email already exists, log in the user
        try {
          const result = await signInWithEmailAndPassword(auth, email, password);
          console.log("Email Sign-In Result:", result);
          const isNew = result.user.metadata.creationTime === result.user.metadata.lastSignInTime;
          console.log("Is New User from Email Sign-In:", isNew);
          setIsNewUser(isNew);
          if (isNew) {
            setStep(2);
          } else {
            navigate('/dashboard');
          }
        } catch (loginError) {
          console.error('Login error:', loginError.message);
          if (loginError.code === 'auth/wrong-password') {
            setError('Incorrect password. Please try again.');
          } else {
            setError('Login failed. Please check your credentials and try again.');
          }
        }
      } else {
        // Email doesn't exist, create a new user
        try {
          const result = await createUserWithEmailAndPassword(auth, email, password);
          console.log("Email Sign-Up Result:", result);
          setIsNewUser(true); // New user, proceed to step 2
          setStep(2);
        } catch (registerError) {
          if (registerError.code === 'auth/email-already-in-use') {
            console.log('Registration error - email already in use. Trying to log in instead.');
            try {
              const result = await signInWithEmailAndPassword(auth, email, password);
              console.log("Email Sign-In After Registration Result:", result);
              const isNew = result.user.metadata.creationTime === result.user.metadata.lastSignInTime;
              console.log("Is New User from Email Sign-In After Registration:", isNew);
              setIsNewUser(isNew);
              if (isNew) {
                setStep(2);
              } else {
                navigate('/dashboard');
              }
            } catch (loginAfterRegisterError) {
              console.error('Login after registration error:', loginAfterRegisterError.message);
              if (loginAfterRegisterError.code === 'auth/wrong-password') {
                setError('Incorrect password. Please try again.');
              } else {
                setError('Login failed. Please check your credentials and try again.');
              }
            }
          } else {
            console.error('Registration error:', registerError.message);
            setError('Registration failed. Please try again.');
          }
        }
      }
    } catch (error) {
      console.error('Fetch sign-in methods error:', error.message);
      setError('Error signing in. Please try again.');
    }
  };

  const handleAdditionalInfoSubmit = async (e) => {
    e.preventDefault();
    console.log('Additional info submitted with:', { firstName, lastName, shoeSizeLeft, shoeSizeRight, profilePic });
  
    const userData = {
      uid: currentUser.uid,
      email: currentUser.email,
      firstName,
      lastName,
      shoeSizeLeft,
      shoeSizeRight,
      profilePic, // Include profile picture URL
    };

    try {
      await axios.post('/.netlify/functions/saveAirtable', userData, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      // Send registration email via Mailjet
      await axios.post('/.netlify/functions/sendMail', {
        recipientEmail: currentUser.email,
        templateId: 6204107, // Replace with your actual Mailjet template ID
        senderEmail: '',
        senderFirstname: '',
        match: {
          userFirstName: firstName,
          title: '',
          productLink: '',
          price: '', 
          productPicture: '', 
          supplierLogo: '', 
          category: ''
        }
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      navigate('/dashboard');
    } catch (error) {
      console.error('Error saving user data:', error.message);
      setError('Failed to save additional information. Please try again.');
    }
  };

  return (
    <div className="register-bg d-flex align-items-start pt-5">
      <div className="text-center container mt-5">
        <h1 className="display-4 fw-bold">Word lid en vind jouw zoolmaatje</h1>
        <p className="lead mb-5">Gratis en je bent tot niets verplicht</p>
        {step === 1 ? (
          showEmailForm ? (
            <form onSubmit={handleEmailSubmit} className="mb-3 mx-auto" style={{ maxWidth: '300px', textAlign: 'left' }}>
              <div className="form-group">
                <label htmlFor="email" className="fw-normal">E-mail</label>
                <input 
                  type="email" 
                  className="form-control form-control-sm" 
                  id="email"
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  required 
                />
              </div>
              <div className="form-group">
                <label htmlFor="password" className="fw-normal">Wachtwoord</label>
                <input 
                  type="password" 
                  className="form-control form-control-sm" 
                  id="password"
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                  required 
                />
              </div>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-custom btn-lg mb-3 me-2">Aanmelden</button>
              </div>
            </form>
          ) : (
            <>
              <button className="btn btn-custom btn-lg mb-5" onClick={() => setShowEmailForm(true)}>Aanmelden met e-mail</button>
              <p className="fw-bold">Of koppel aan een bestaand account</p>
              <button className="btn btn-google btn-lg rounded-pill" onClick={handleGoogleSignIn}>
                <FontAwesomeIcon icon={faGoogle} /> Ga verder met Google
              </button>
            </>
          )
        ) : (
          <form onSubmit={handleAdditionalInfoSubmit} className="mb-3 mx-auto" style={{ maxWidth: '300px', textAlign: 'left' }}>
            <div className="form-group">
              <label htmlFor="firstName" className="fw-normal">Voornaam</label>
              <input 
                type="text" 
                className="form-control form-control-sm" 
                id="firstName"
                value={firstName} 
                onChange={(e) => setFirstName(e.target.value)} 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName" className="fw-normal">Achternaam</label>
              <input 
                type="text" 
                className="form-control form-control-sm" 
                id="lastName"
                value={lastName} 
                onChange={(e) => setLastName(e.target.value)} 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor="shoeSizeLeft" className="fw-normal">Schoenmaat links</label>
              <input 
                type="range" 
                className="form-range" 
                id="shoeSizeLeft"
                min="34" 
                max="50" 
                value={shoeSizeLeft} 
                onChange={(e) => setShoeSizeLeft(e.target.value)} 
                required 
              />
              <div className="text-center">{shoeSizeLeft}</div>
            </div>
            <div className="form-group">
              <label htmlFor="shoeSizeRight" className="fw-normal">Schoenmaat rechts</label>
              <input 
                type="range" 
                className="form-range" 
                id="shoeSizeRight"
                min="34" 
                max="50" 
                value={shoeSizeRight} 
                onChange={(e) => setShoeSizeRight(e.target.value)} 
                required 
              />
              <div className="text-center">{shoeSizeRight}</div>
            </div>
            <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-custom btn-lg mb-3 mt-3">Registreren</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Register;