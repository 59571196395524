import React from 'react';
import './Filters.css'; // General CSS file for all filters

const LikedProductsFilter = ({ showLikedProducts, setShowLikedProducts }) => {
  
  const handleLikedProductsChange = () => {
    setShowLikedProducts(prevShowLikedProducts => {
      const newState = !prevShowLikedProducts;
      console.log('Liked Products Filter Toggled:', newState);
      return newState;
    });
  };

  return (
    <div className="liked-products-filter">
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="likedProducts"
          checked={showLikedProducts}
          onChange={handleLikedProductsChange}
        />
        <label className="form-check-label" htmlFor="likedProducts">
          Mijn favorieten
        </label>
      </div>
    </div>
  );
};

export default LikedProductsFilter;