// src/components/ContentPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Spinner, Alert } from 'react-bootstrap';
import './ContentPage.css'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ContentPage = () => {
  const { slug } = useParams(); // Dynamically get the slug from the URL
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/.netlify/functions/fetchContent', {
          params: { slug } // Use the dynamic slug to fetch the correct content
        });
        setData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]); // Re-run the effect when the slug changes

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  // Custom render options for rendering images and styling headers
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { file, title } = node.data.target.fields;
        return (
          <img
            src={file.url}
            alt={title || 'Image'}
            className="content-image" // Add a custom class to the image
          />
        );
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 className="blog-h2">{children}</h2>;
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3 className="blog-h3">{children}</h3>;
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return <h4 className="blog-h4">{children}</h4>;
      },
      // Add more custom elements as needed
    },
  };

  return (
    <Container className='blog-container'>
        <Helmet>
        <title>{data.blogTitle} - Zoolmaatje.nl</title>
        <meta name="description" content={data.metaDescription || "Default description for the content page."} />
        <meta property="og:title" content={data.blogTitle} />
        <meta property="og:description" content={data.metaDescription || "Default description for the content page."} />
        <meta property="og:url" content={window.location.href} />
        {/* Add more meta tags as needed, e.g., for Open Graph or Twitter Cards */}
      </Helmet>
      <h1 className="blog-title">{data.blogTitle}</h1>
      <div>{documentToReactComponents(data.blogContent, options)}</div>
    </Container>
  );
};

export default ContentPage;