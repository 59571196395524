import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ProductList from './ProductList/ProductList';
import PriceFilter from './Filters/PriceFilter';
import SupplierFilter from './Filters/SupplierFilter';
import CategoryFilter from './Filters/CategoryFilter';
import LikedProductsFilter from './Filters/LikedProductsFilter';
import SkeletonLoader from './SkeletonLoader/SkeletonLoader';
import './ProductOverview.css';

const ProductOverview = () => {
  const [products, setProducts] = useState([]);
  const [filterPrice, setFilterPrice] = useState(250);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [likedProducts, setLikedProducts] = useState(new Set());
  const [showLikedProducts, setShowLikedProducts] = useState(false);
  const [userID, setUserID] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const initialLoad = useRef(true);
  const location = useLocation();

  const prevFilters = useRef({
    selectedCategory,
    filterPrice,
    selectedSuppliers,
    showLikedProducts,
  });

  const fetchProducts = useCallback(async (initial = false) => {
    if (loading || (!initial && !hasMore) || !filtersInitialized) return;

    setLoading(true);
    try {
      console.log('Fetching products with params:', {
        limit: 50,
        offset: initial ? null : offset,
        price: filterPrice,
        suppliers: selectedSuppliers.length > 0 ? selectedSuppliers.join(',') : undefined,
        category: selectedCategory || undefined,
        liked: showLikedProducts && userID ? userID : undefined,
      });

      const response = await axios.get('/.netlify/functions/getProducts', {
        params: {
          limit: 50,
          offset: initial ? null : offset,
          price: filterPrice,
          suppliers: selectedSuppliers.length > 0 ? selectedSuppliers.join(',') : undefined,
          category: selectedCategory || undefined,
          liked: showLikedProducts && userID ? userID : undefined,
        },
      });

      const { products: newProducts, offset: newOffset } = response.data;

      setProducts(prevProducts => (initial ? newProducts : [...prevProducts, ...newProducts]));
      setOffset(newOffset);
      setHasMore(!!newOffset);
      setLoading(false);

      if (initial) {
        setCategories(response.data.categories);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setLoading(false);
    }
  }, [loading, hasMore, offset, filterPrice, selectedSuppliers, selectedCategory, showLikedProducts, userID, filtersInitialized]);

  const fetchFilters = useCallback(async () => {
    try {
      const response = await axios.get('/.netlify/functions/getFilters');
      setSuppliers(response.data.suppliers);
      setCategories(response.data.categories);
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get('category');
    const price = params.get('price');
    const suppliers = params.getAll('suppliers');
    const liked = params.get('liked') === 'true';

    console.log('URL params:', { category, price, suppliers, liked });

    if (category && category !== selectedCategory) setSelectedCategory(category);
    if (price && Number(price) !== filterPrice) setFilterPrice(Number(price));
    if (suppliers.length > 0 && JSON.stringify(suppliers) !== JSON.stringify(selectedSuppliers)) setSelectedSuppliers(suppliers);
    if (liked !== showLikedProducts) setShowLikedProducts(liked);

    setFiltersInitialized(true);
    // eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    if (filtersInitialized && !initialLoad.current) {
      const { selectedCategory: prevCategory, filterPrice: prevPrice, selectedSuppliers: prevSuppliers, showLikedProducts: prevLiked } = prevFilters.current;

      if (
        prevCategory !== selectedCategory ||
        prevPrice !== filterPrice ||
        JSON.stringify(prevSuppliers) !== JSON.stringify(selectedSuppliers) ||
        prevLiked !== showLikedProducts
      ) {
        fetchProducts(true);
      }

      prevFilters.current = {
        selectedCategory,
        filterPrice,
        selectedSuppliers,
        showLikedProducts,
      };
    }
  }, [selectedCategory, filterPrice, selectedSuppliers, showLikedProducts, filtersInitialized, fetchProducts]);

  useEffect(() => {
    if (initialLoad.current && filtersInitialized) {
      fetchProducts(true);
      initialLoad.current = false;
    }
  }, [fetchProducts, filtersInitialized]);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const toggleLike = async (productID) => {
    if (!userID) {
      setSnackbarMessage('Log in om producten te liken.');
      setSnackbarOpen(true);
      return;
    }

    try {
      await axios.post('/.netlify/functions/updateLikedProducts', {
        uid: userID,
        productID: productID,
      });

      setLikedProducts((prevLikedProducts) => {
        const updatedLikedProducts = new Set(prevLikedProducts);
        if (updatedLikedProducts.has(productID)) {
          updatedLikedProducts.delete(productID);
        } else {
          updatedLikedProducts.add(productID);
        }

        return updatedLikedProducts;
      });
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading || !hasMore) {
        return;
      }
      fetchProducts();
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchProducts, loading, hasMore]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserID(user.uid);
      } else {
        setUserID(null);
      }
    });
  }, []);

  useEffect(() => {
    if (!userID) return;

    const fetchLikedProducts = async () => {
      try {
        const response = await axios.get(`/.netlify/functions/getUserLikedProducts?uid=${userID}`);
        const likedProductsData = response.data.map(item => item.productID);
        setLikedProducts(new Set(likedProductsData));
      } catch (error) {
        console.error('Error fetching liked products:', error);
      }
    };

    fetchLikedProducts();
  }, [userID]);

  useEffect(() => {
    console.log('showLikedProducts state:', showLikedProducts);
  }, [showLikedProducts]);

  return (
    <div className="product-overview container py-5">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <button className="btn btn-primary d-md-none fixed-filter-button" onClick={handleModalShow}>
        Filters
      </button>
      <div className="row">
        <div className="col-md-3 d-none d-md-block">
          <div className="filter-section">
            <PriceFilter filterPrice={filterPrice} setFilterPrice={setFilterPrice} />
            <SupplierFilter suppliers={suppliers} selectedSuppliers={selectedSuppliers} setSelectedSuppliers={setSelectedSuppliers} />
            <CategoryFilter categories={categories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
            {userID && <LikedProductsFilter showLikedProducts={showLikedProducts} setShowLikedProducts={setShowLikedProducts} />}
          </div>
        </div>
        <div className="col-md-9">
          <ProductList products={products} likedProducts={likedProducts} toggleLike={toggleLike} loading={loading} hasMore={hasMore} />
          {loading && hasMore && <SkeletonLoader />}
        </div>
      </div>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-section">
            <PriceFilter filterPrice={filterPrice} setFilterPrice={setFilterPrice} />
            <SupplierFilter suppliers={suppliers} selectedSuppliers={selectedSuppliers} setSelectedSuppliers={setSelectedSuppliers} />
            <CategoryFilter categories={categories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
            {userID && <LikedProductsFilter showLikedProducts={showLikedProducts} setShowLikedProducts={setShowLikedProducts} />}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProductOverview;