import React from 'react';
import './Filters.css'; // General CSS file for all filters

const SupplierFilter = ({ suppliers, selectedSuppliers, setSelectedSuppliers }) => {
  
  const handleSupplierChange = (supplier) => {
    setSelectedSuppliers(prevSelectedSuppliers => {
      const newSelectedSuppliers = prevSelectedSuppliers.includes(supplier)
        ? prevSelectedSuppliers.filter(s => s !== supplier)
        : [...prevSelectedSuppliers, supplier];
      return newSelectedSuppliers;
    });
  };

  return (
    <div className="supplier-filter">
      <h5>Aanbieders</h5>
      {suppliers.map(supplier => (
        <div key={supplier} className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id={supplier}
            value={supplier}
            checked={selectedSuppliers.includes(supplier)}
            onChange={() => handleSupplierChange(supplier)}
          />
          <label className="form-check-label" htmlFor={supplier}>
            {supplier}
          </label>
        </div>
      ))}
    </div>
  );
};

export default SupplierFilter;