import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  // Import Link
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css'; 

const Footer = () => {
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get('/.netlify/functions/fetchContent', {
          params: { type: 'websiteFooter' }
        });
        setContent(response.data); // Assuming response.data is an array of entries
      } catch (error) {
        setError('Failed to load content. Please try again later.');
      }
    };

    fetchContent();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (content === null) {
    return <div>Loading...</div>;
  }

  const col1 = content[0]?.col1 ?? [];
  const col2 = content[0]?.col2 ?? [];
  const col3 = content[0]?.col3 ?? [];

  const col1Title = content[0]?.col1Title || "Column 1";
  const col2Title = content[0]?.col2Title || "Column 2";
  const col3Title = content[0]?.col3Title || "Column 3";

  return (
    <footer className="footer mt-auto py-3">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h5 className='footer'>{col1Title}</h5>
            <ul>
              {Array.isArray(col1) && col1.length > 0 ? col1.map((item, index) => (
                <li key={index}>
                  {/* Use Link component instead of <a> */}
                  <Link to={item.fields.footerUrl}>{item.fields.footerTitle}</Link>
                </li>
              )) : <div>No content for column 1</div>}
            </ul>
          </div>
          <div className="col-md-4">
            <h5 className='footer'>{col2Title}</h5>
            <ul>
              {Array.isArray(col2) && col2.length > 0 ? col2.map((item, index) => (
                <li key={index}>
                  {/* Use Link component instead of <a> */}
                  <Link to={item.fields.footerUrl}>{item.fields.footerTitle}</Link>
                </li>
              )) : <div>No content for column 2</div>}
            </ul>
          </div>
          <div className="col-md-4">
            <h5 className='footer'>{col3Title}</h5>
            <ul>
              {Array.isArray(col3) && col3.length > 0 ? col3.map((item, index) => (
                <li key={index}>
                  {/* Use Link component instead of <a> */}
                  <Link to={item.fields.footerUrl}>{item.fields.footerTitle}</Link>
                </li>
              )) : <div>No content for column 3</div>}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;