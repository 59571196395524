import React, { useState, useEffect, useRef } from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaUserGroup, FaUser, FaHeart } from "react-icons/fa6";
import { FaSignOutAlt } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Sidebar.css'; 
import Avatar from '@mui/material/Avatar'; 

const Sidebar = ({ setView, userData, handleUpdate }) => {
  const navigate = useNavigate();
  const [profilePic, setProfilePic] = useState('/defaultProfile.png'); 
  const [isOpen, setIsOpen] = useState(window.innerWidth > 1000);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchProfilePic = () => {
      const firebaseUser = auth.currentUser;

      if (firebaseUser && firebaseUser.photoURL) {
        setProfilePic(firebaseUser.photoURL); 
      } else if (userData?.fields?.profilePic) {
        setProfilePic(userData.fields.profilePic); 
      }
    };

    fetchProfilePic();
  }, [userData]);

  // Handle window resize to manage sidebar visibility
  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth > 1000);
    };
    
    window.addEventListener('resize', handleResize);
    
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/registreer');
    } catch (error) {
      console.error('Error logging out: ', error);
    }
  };

  const handleProfilePicClick = () => {
    if (profilePic === '/defaultProfile.png') {
      fileInputRef.current.click(); 
    }
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        handleSubmit(file); 
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);

      try {
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/ds2dnls3o/image/upload`,
          formData
        );

        const profilePicUrl = response.data.secure_url;
        console.log('Profile Pic URL:', profilePicUrl); 
        setProfilePic(profilePicUrl);  
        handleUpdate(profilePicUrl);  
      } catch (error) {
        console.error('Upload Error:', error.response ? error.response.data : error.message);
      }
    }
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <div className="user-info">
        <Avatar
          src={profilePic || "/defaultProfile.png"} 
          alt="User"
          className="user-img"
          onClick={handleProfilePicClick} 
          onError={(e) => { e.target.src = '/defaultProfile.png'; }} 
          sx={{ width: 50, height: 50 }} 
        />
        {isOpen && (
          <div className="user-details">
            <h4>{userData?.fields?.firstName || ''}</h4> 
          </div>
        )}
        <input 
          type="file" 
          ref={fileInputRef} 
          style={{ display: 'none' }} 
          accept="image/*" 
          onChange={handleProfilePicChange}  
        />
      </div>
      <nav>
        <ul>
        <li>
  <button className="nav-link" onClick={() => setView('profile')}>
    <FaUser style={{ fontSize: '24px' }} /> {/* Adjust size here */}
    {isOpen && <span>Profiel</span>}
  </button>
</li>
<li>
  <button className="nav-link" onClick={() => setView('zoolmaatjes')}>
    <FaUserGroup style={{ fontSize: '24px' }} /> {/* Adjust size here */}
    {isOpen && <span>Zoolmaatjes</span>}
  </button>
</li>
<li>
  <button className="nav-link" onClick={() => navigate('/schoenen?liked=true')}>
    <FaHeart style={{ fontSize: '24px' }} /> {/* Adjust size here */}
    {isOpen && <span>Favorieten</span>}
  </button>
</li>
        </ul>
      </nav>
      <div className="bottom-section">
        <ul>
          <li><button className="nav-link" onClick={handleLogout}>
            <FaSignOutAlt style={{ fontSize: '24px' }} />{isOpen && <span>Logout</span>}</button></li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;