import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import './ProductCard.css'; // Specific CSS file for ProductCard component

const ProductCard = ({ product, isLiked, toggleLike }) => {
  // Debug to check if the correct icon is being rendered
  console.log('Product:', product.productID, 'Is Liked:', isLiked);

  return (
    <div>
      <div className="card product-card h-100 text-decoration-none">
        <div className="checkbox-container">
          <Checkbox
            icon={<FavoriteBorder />}
            checkedIcon={<Favorite sx={{ color: '#FF9775' }} />}
            checked={isLiked}
            onClick={(e) => {
              e.preventDefault();
              toggleLike(product.productID);
            }}
            inputProps={{ 'aria-label': 'favorite checkbox' }}
          />
        </div>
        <a href={product.productLink} target="_blank" rel="noopener noreferrer">
          <img src={product.productPicture} className="card-img-top" alt={product.title} />
        </a>
        <div className="card-body">
          <a href={product.productLink} target="_blank" rel="noopener noreferrer">
            <h5 className="card-title">{product.title}</h5>
          </a>
          <div className="card-price-supplier">
            <p className="card-price"><strong>€ {product.price}</strong></p>
            <div className="supplier-logo">
              <img src={product.supplierLogo} alt="Supplier Logo" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;