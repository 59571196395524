import React from 'react';
import './Filters.css'; // General CSS file for all filters

const CategoryFilter = ({ categories, selectedCategory, setSelectedCategory }) => {
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <div className="category-filter">
      <h5>Categorieën</h5>
      <select className="form-select" value={selectedCategory} onChange={handleCategoryChange}>
        <option value="">Selecteer een categorie</option>
        {categories.map(category => (
          <option key={category} value={category}>{category}</option>
        ))}
      </select>
    </div>
  );
};

export default CategoryFilter;